import React from 'react';
import { Helmet } from 'react-helmet';
import { Consts } from 'consts';
import { useTranslation } from "react-i18next";

import "styles/theme.css";
import AboutPage from './about';

const Home = () => {
    const [t] = useTranslation('common');

    return (
        <>
            <Helmet>
                <title>{t('SvcTmpl Home')} | {Consts.ProjectName}</title>
                <meta name="description" content={t('Service template home page')} />
            </Helmet>
            <AboutPage/>
        </>
    );
}

export default Home;
import React from "react";
import {Row, Col} from 'react-bootstrap';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export function unixTimestampToString(ts) {
    const dateObj = new Date(1000*ts);
    return dateObj.toUTCString();
}

export function timestampToString(ts) {
    const dateObj = new Date(ts/1000);
    return dateObj.toUTCString();
}

export function timestampToStringWithMillis(ts) {
    const dateObj = new Date(ts/1000);
    return dateObj.toISOString();
}

export const capitalizeFirstLetter = (string) => (
    string[0].toUpperCase() + string.slice(1)
);

export const getFirstLetterOfEachWord = (text) => (
    text.match(/\b\w/g).join('')
);

export const SwalWithBootstrapButtons = withReactContent(Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-primary me-3',
      cancelButton: 'btn btn-gray'
    },
    buttonsStyling: false
}));

export const showError = async (error) => (
    await SwalWithBootstrapButtons.fire({
        icon: "error",
        title: "Something went wrong",
        text: error,
        confirmButtonText: "OK",
      })
);

export const showMessage = async (title, message) => (
    await SwalWithBootstrapButtons.fire({
        icon: "info",
        title: title,
        text: message,
        confirmButtonText: "OK",
      })
);

export const showConfirmation = async (title, message) => (
    await SwalWithBootstrapButtons.fire({
        icon: "error",
        title: title,
        text: message,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No"
    })
);

export const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

export const DividerOr = () => {
    return (
        <Row className="text-center mt-4">
            <Col>
                <hr/>
            </Col>
            <Col>
                <strong>or</strong>
            </Col>
            <Col>
                <hr/>
            </Col>
      </Row>
    )
}
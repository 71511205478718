import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import Header from 'components/header';
import Footer from 'components/footer';
import { Consts } from "consts";
import { useTranslation } from "react-i18next";

const AboutPage = () => {
  const [t] = useTranslation('common');

  return (
    <div>
        <Helmet>
            <title>{t('About')} | {Consts.ProjectName}</title>
            <meta name="description" content={`${t('About')} ${Consts.ProjectName}`} />
        </Helmet>
        <Header/>
        <Container className="my-5">
            <Row className="justify-content-md-center">
                <Col>
                <h2 className="mb-4">{t('About')} {Consts.ProjectName}</h2>
                <p>
                {t('Welcome to')} <strong>{Consts.ProjectName}</strong> {t('your ultimate TBD')}
                </p>

                <h3 className="mt-5 mb-3">{t('What We Offer')}</h3>
                <ul>
                    <li>
                    <strong>{t('TBD')}:</strong> {t('TBD')}
                    </li>
                    <li>
                    <strong>{t('TBD')}:</strong> {t('TBD')}
                    </li>
                </ul>

                <h3 className="mt-5 mb-3">{t('Our Mission')}</h3>
                <p>
                {t('At')} <strong>{Consts.ProjectName}</strong> {t('TBD')}
                </p>

                <h3 className="mt-5 mb-3">{t('Want to Help or Partner with Us?')}</h3>
                <p>
                {t('If you’d like to help us improve the service or explore partnership opportunities, feel free to reach out to')} {' '}
                <a href="mailto:info@svctmpl.ai">info@svctmpl.ai</a>.
                </p>
                </Col>
            </Row>
        </Container>
        <Footer/>
    </div>
  );
};

export default AboutPage;
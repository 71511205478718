export const Paths = {
    Home: { path: "/" },
    NotFound: { path: "/404" },
    ServerError: { path: "/500" },
    About: { path: "/about" },
    Signin: { path: "/signin" },
    Signup: { path: "/signup" },
    Profile: { path: "/profile" },
    Settings: { path: "/settings" },
    Billing: { path: "/billing" },
    Pricing: { path: "/pricing" },
    Notifications: { path: "/notifications" },
    VerifyEmail: { path: "/verify-email" },
};

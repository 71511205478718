import React from "react";
import { Container } from 'react-bootstrap';
import { Consts } from "consts";
import { useTranslation } from "react-i18next";
import "styles/theme.css";

const Footer = (props) => {
  const { t } = useTranslation('common');

  return (
    <footer className="footer p-4 text-center mt-auto py-3 bg-light border-top">
        <Container>
        <span>&copy; 2024 {Consts.ProjectName} v{process.env.REACT_APP_VERSION}. {t('All Rights Reserved.')}</span>
        </Container>
    </footer>
  );
};

export default Footer;

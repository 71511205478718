import { React, useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import Header from 'components/header';
import Footer from 'components/footer';
import { Consts } from "consts";
import getBackend from 'backend/backend';
import Sidebar from 'components/sidebar';
import { useTranslation } from "react-i18next";
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';

const Settings = () => {
    const [t] = useTranslation('common');
    const [settings, setSettings] = useState({ brightness: 0, secret: '' });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let canceled = false;

        const fetchSettings = async () => {
            try {
                const result = await getBackend().getSettings();
                if (!canceled && result.error == null) {
                    console.log(result.response.settings);
                    setSettings(result.response.settings);
                } else if (!canceled) {
                    setError(result.error);
                }
            } catch (err) {
                if (!canceled) setError(err.message);
            } finally {
                if (!canceled) setLoading(false);
            }
        };

        fetchSettings();

        return () => {
            canceled = true;
        };
    }, []);

    const handleBrightnessChange = (e) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            brightness: parseInt(e.target.value)
        }));
    };

    const handleSecretChange = (e) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            secret: e.target.value
        }));
    };

    const handleSave = async () => {
        try {
            const result = await getBackend().setSettings({"settings": settings});
            if (result.error != null) {
                setError(result.error);
            }
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <>
            <Helmet>
                <title>{t('Settings')} | {Consts.ProjectName}</title>
                <meta name="description" content={`${t('Settings')} ${Consts.ProjectName}`} />
            </Helmet>
            <Header />

            <Sidebar>
                <Container className="my-5">

                <h2 className="mb-4">{t('Settings')}</h2>
                {loading &&
                    <div>{t('Loading...')}</div>
                }
                {error &&
                    <Alert variant="danger">{t('Error')}: {error}</Alert>
                }
                <Form>
                    <Form.Group as={Row} controlId="formBrightness">
                        <Form.Label column sm="2">{`${t('Brightness')} (${settings.brightness})`}</Form.Label>
                        <Col sm="10">
                            <Form.Range
                                value={settings.brightness}
                                onChange={handleBrightnessChange}
                                min={0}
                                max={100}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formSecret">
                        <Form.Label column sm="2">{t('Secret')}</Form.Label>
                        <Col sm="10">
                            <Form.Control
                                type="text"
                                name="Secret"
                                value={settings.secret}
                                onChange={handleSecretChange}
                            />
                        </Col>
                    </Form.Group>
                    <Button variant="primary" onClick={handleSave}>{t('Save')}</Button>
                </Form>
                </Container>
            </Sidebar>

            <Footer />
        </>
    );
};

export default Settings;

import {React, useState, useEffect} from 'react';
import {Helmet} from "react-helmet";
import Header from 'components/header';
import Footer from 'components/footer';
import { Consts } from "consts";
import getBackend from 'backend/backend';
import { Paths } from 'paths';
import Sidebar from 'components/sidebar';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { Button, Alert, Container } from 'react-bootstrap';
import { showConfirmation } from "components/misc";

const Profile = () => {
    const [profile, setProfile] = useState({});
    const [t] = useTranslation('common');
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    useEffect(() => {
        let canceled = false;

        const getProfile = async () => {
            const result = await getBackend().profile();
            if (canceled)
            return;

            if (result.error == null) {
                setProfile(result.response);
            } else {
                window.location.href = Paths.NotFound.path;
            }
        };

        getProfile();

        return async () => {
            canceled = true;
        }
    }, []);

    const handleDeleteAccount = async () => {
        let result = await showConfirmation("Delete account", "Are you really going to delete the account?");
        if (result.isConfirmed) {
            const result = await getBackend().deleteAccount();
            if (result.error === null) {
                navigate(Paths.Home.path);
            } else {
                setError(t('Error deleting account'));
            }
        }
    };

    return (
    <>
        <Helmet>
            <title>{t('Profile')} | {Consts.ProjectName}</title>
            <meta name="description" content={`${t('Profile')} ${Consts.ProjectName}`} />
        </Helmet>
        <Header/>

        <Sidebar>
            <Container className="my-5">
                <h2 className="mb-4">{t('Profile')}</h2>
                <p className="mb-4">{t('Name')} {profile.name}</p>
                <p className="mb-4">UID {profile.id}</p>
                {error && <Alert variant="danger">{error}</Alert>}
                <Button variant="danger" onClick={handleDeleteAccount}>{t('Delete Account')}</Button>
            </Container>
        </Sidebar>

        <Footer/>
    </>
  );
};

export default Profile;
